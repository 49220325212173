import { defineMessages, useIntl } from 'gatsby-plugin-react-intl';
import PropTypes from 'prop-types';
import React from 'react';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import { Divider, SectionDivider } from '../../components/Grid/Divider';
import Hero from '../../components/Hero/Hero';
import Instant from '../../components/Instant/Instant';
import Layout from '../../components/Layout/Layout';
import Market from '../../components/Market/Market';
import PopularQuestions from '../../components/PopularQuestions/PopularQuestions';
import Sell from '../../components/Sell/Sell';
import SEO, { createAlternateLinks } from '../../components/Seo/Seo';
import Trustpilot from '../../components/Trustpilot/Trustpilot';
import UniqueValuePropositions from '../../components/UniqueValuePropositions/UniqueValuePropositions';

import { GAME } from '../../constants';

const messages = defineMessages({
  title: {
    id: 'index.title.DOTA2',
    defaultMessage:
      'Sell Dota 2 items for Real Money & Withdraw to the e-wallet of your choice',
  },
  description: {
    id: 'index.description.DOTA2',
    defaultMessage:
      'Sell your Dota 2 items fast and cash out instantly! Log in with your Steam, evaluate your inventory, sell skins and pay out money in 5 minutes.',
  },
});

const getQuestions = (lang) => {
  try {
    // eslint-disable-next-line import/no-dynamic-require, global-require
    return require(`../../intl/questions/dota2/${lang}.json`);
  } catch (error) {
    return null;
  }
};

const Dota2IndexPage = ({
  location: { pathname, hash },
  pageContext: {
    intl: { originalPath },
  },
}) => {
  const { locale, formatMessage } = useIntl();
  const questions = getQuestions(locale);
  const game = GAME.DOTA2;
  const alternateLinks = createAlternateLinks(originalPath);

  return (
    <>
      <SEO
        lang={locale}
        title={formatMessage(messages.title)}
        description={formatMessage(messages.description)}
        pathname={pathname}
        link={alternateLinks}
      />
      <Layout game={game}>
        <Hero game={game} originalPath={originalPath} hash={hash} />
        <LazyLoadComponent>
          <Trustpilot />
        </LazyLoadComponent>
        <Divider />
        <UniqueValuePropositions game={game} />
        <SectionDivider />
        <Instant game={game} />
        <SectionDivider />
        <Market />
        <SectionDivider />
        <Sell game={game} />
        {questions && (
          <>
            <Divider />
            <PopularQuestions questions={questions} />
          </>
        )}
      </Layout>
    </>
  );
};

Dota2IndexPage.propTypes = {
  location: PropTypes.object.isRequired,
  pageContext: PropTypes.object.isRequired,
};

export default Dota2IndexPage;
